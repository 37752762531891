var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:" container"},[_c('v-row',{staticStyle:{"width":"100%"}},[_c('v-col',{staticClass:"mt-4",attrs:{"cols":"8","offset-lg":"2","offset-md":"2"}},[_c('Breadcrumbs',{attrs:{"items":[
          {
            text: 'All Staff',
            disabled: false,
            href: '/admin/staffs/all',
          },
          {
            text: !!_vm.$route.query.id ? 'Edit Staff' : 'Add Staff',
            disabled: true,
            href: !!_vm.$route.query.id
              ? '/admin/staffs/add'
              : '/admin/staffs/all',
          } ]}}),_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex flex-column mb-3 ml-1 mt-n4"},[_c('PageTitle',{staticClass:" font font-weight-bold font-size-lg mb-4",attrs:{"text":!!_vm.$route.query.id ? 'Edit Staff Details' : "New Staff"}})],1),_c('div',{staticClass:"d-flex flex-column mx-2 flex-grow-1"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.addNewStaff)}}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-text-field',{staticClass:"font font-weight-medium font-size-md",attrs:{"label":"Name","outlined":"","hint":"Enter the name of the staff ","error-messages":errors[0]},model:{value:(_vm.staff.name),callback:function ($$v) {_vm.$set(_vm.staff, "name", $$v)},expression:"staff.name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|numeric|min:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-text-field',{staticClass:"font font-weight-medium font-size-md",attrs:{"label":"Phone Number","outlined":"","hint":"Enter your phone number ","error-messages":errors[0]},model:{value:(_vm.staff.phone),callback:function ($$v) {_vm.$set(_vm.staff, "phone", $$v)},expression:"staff.phone"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-text-field',{staticClass:"font font-weight-medium font-size-md",attrs:{"label":"Account Number","outlined":"","hint":"Enter your account number ","error-messages":errors[0]},model:{value:(_vm.staff.accountNumber),callback:function ($$v) {_vm.$set(_vm.staff, "accountNumber", $$v)},expression:"staff.accountNumber"}})]}}],null,true)}),(!_vm.$route.query.id || _vm.$route.query.id === _vm.profile.id)?_c('div',{staticClass:" d-flex flex-row flex-grow-1"},[_c('ValidationProvider',{staticClass:"d-flex flex-column flex-grow-1 mr-1",attrs:{"rules":!_vm.$route.query.id ? 'required|numeric' : '',"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-text-field',{staticClass:"font font-weight-medium font-size-md",attrs:{"label":"Enter your PIN Code","outlined":"","type":"password","hint":"Enter your PIN Code ","error-messages":errors[0]},model:{value:(_vm.staff.pin),callback:function ($$v) {_vm.$set(_vm.staff, "pin", $$v)},expression:"staff.pin"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-column flex-grow-1 ml-1",attrs:{"rules":!_vm.$route.query.id ? 'required|numeric' : '',"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-text-field',{staticClass:"font font-weight-medium font-size-md",attrs:{"label":"Confirm your PIN Code","outlined":"","type":"password","hint":"Confirm your PIN Code","error-messages":errors[0]},model:{value:(_vm.staff.confirmPin),callback:function ($$v) {_vm.$set(_vm.staff, "confirmPin", $$v)},expression:"staff.confirmPin"}})]}}],null,true)})],1):_vm._e(),_c('div',{staticClass:"d-flex flex-row flex-grow-1"},[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.isLoading,"loading":_vm.isLoading,"color":"primary","type":"submit"}},[_c('span',{staticClass:"font font-sm font-weight-medium "},[_vm._v("Save ")])])],1)],1)]}}])})],1)])],1)],1)],1),_c('SnackBar',{attrs:{"showSnackBar":_vm.showSnackBar,"message":_vm.message,"status":_vm.status}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }